/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import UpdateSpinner from '../UpdateSpinner';
import PaymentLinkForm from './PaymentLinkForm';
import Loading from '../Loading';
import query from 'query-string';
import SvgIcon from '@mui/material/SvgIcon';
import RightChevronIcon from '../icons/RightChevronIcon';
import Button from '../shared/Button';
import CountdownTimer from '../CountdownTimer';
import MerchantLogo from '../MerchantLogo';
import MessageDialog from '../shared/MessageDialog';
import _ from 'lodash';
import moment from 'moment/moment';
import { reset } from 'redux-form';
import ReactRecaptcha3 from 'react-google-recaptcha3';

import FormatTextUtil from '../util/FormatTextUtil';
import LabelUtil from '../util/LabelUtil';
import LocalStorageUtil from '../util/LocalStorageUtil';
import PaymentUtil from '../util/PaymentUtil';
import TaxUtil from '../util/TaxUtil';
import { roundToTwoDecimals } from '../util/CommonUtil';

import actionTypes from '../../constants/actionTypes';
import messages from '../../constants/messages';
import paymentLinks from '../../constants/paymentLinks';

import { getPaymentLinkGeoTax } from '../../actions/paymentLinksActions';
import { setTaxRate } from '../../actions/taxesActions';
import { suppressAppError } from '../../actions/userExperienceActions';
import PaymentLinkSuccessImg from './images/paymentLinkSuccessImg';


export default class PaymentLinkControl extends Component {

  constructor(props) {
    super(props);

    this.process = this.process.bind(this);
    this.processApplePay = this.processApplePay.bind(this);
    this.updatePageTitle = this.updatePageTitle.bind(this);
    this.updateTax = this.updateTax.bind(this);
    this.doAnother = this.doAnother.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleDeclinedModalClose = this.handleDeclinedModalClose.bind(this);
    this.handlePaymentResponse = this.handlePaymentResponse.bind(this);

    this.state = {
      paymentCompleted: false,
      paymentError: null,
      fetchingPosition: false,
      fetchedPosition: false,
      showDeclinedModal: false,
      receiptEmail: '',
      errorMsg: ''
    };
  }

  async componentDidMount() {
    ReactRecaptcha3.init(googleRecaptchaClientId);
  }

  componentDidUpdate(prevState) {
    const { paymentLinks: { paymentLinkForPayment } } = this.props;

    const merchantSettings = paymentLinkForPayment?.merchantSettings;
    const linkData = paymentLinkForPayment?.paymentLink;

    this.updatePageTitle(linkData);
    this.updateTax(linkData, merchantSettings, prevState);
  }

  componentWillUnmount() {
    ReactRecaptcha3.destroy();
  };

  updatePageTitle(linkData) {
    const label = LabelUtil.getLabel();
    const currentTitle = _.get(linkData, 'settings.title');
    const pageTitle = currentTitle ? `${currentTitle} | ${label.text}` : label.text;
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }

  updateTax(linkData, merchantSettings, prevState) {
    if (merchantSettings && linkData?.settings?.tax_enabled) {
      const { dispatch, taxes } = this.props;
      if (merchantSettings.is_geotax_enabled && !taxes?.isFetching) {
        const prevGeoTaxRate = prevState?.taxes?.geoTaxRate;
        const newGeoTaxRate = taxes?.geoTaxRate;

        if (prevGeoTaxRate !== newGeoTaxRate && newGeoTaxRate) {
          LocalStorageUtil.setGeoTax(newGeoTaxRate);
          TaxUtil.updateTaxValue(newGeoTaxRate, this.props);
        } else if (!newGeoTaxRate && !this.state.fetchingPosition && !this.state.fetchedPosition) {
          this.setState({ fetchingPosition: true });
          TaxUtil.getPosition(this.props).then(response => {
            if (response?.coords) {
              const {latitude, longitude} = response.coords;
              const queryString = query.parse(location.search);
              const token = queryString.token;

              dispatch(suppressAppError(true));
              dispatch(getPaymentLinkGeoTax(token, latitude, longitude)).finally(() => {
                dispatch(suppressAppError(false));
              });
            }
          }).finally(() => {
            this.setState({ fetchingPosition: false, fetchedPosition: true });
          });
        }
      } else if (!merchantSettings.is_geotax_enabled) {
        const prevTaxRate = parseFloat(prevState?.paymentLinks?.paymentLinkForPayment?.merchantSettings?.tax_rate);
        const newTaxRate = parseFloat(merchantSettings.tax_rate);

        if (prevTaxRate !== newTaxRate && !_.isNaN(newTaxRate)) {
          dispatch(setTaxRate(newTaxRate));
        }
      }
    }
  }

  handlePaymentResponse(res) {
    const APPROVED_RESPONSE = 'APR';
    const DECLINED_RESPONSE = 'DCL';
    const APPROVED_STATUS_CODE = '00';
    const LOCKED_STATUS_CODE = 'RR';
    const actionType = res?.type;
    const responseCode = res?.response?.response_code;
    const responseStatus = res?.response?.status_code;
    if (actionType === actionTypes.payPaymentLinkSuccess &&
      responseCode === APPROVED_RESPONSE && responseStatus === APPROVED_STATUS_CODE) {
      this.setState({paymentCompleted: true});
    } else {
      if (responseCode === DECLINED_RESPONSE && responseStatus === LOCKED_STATUS_CODE) {
        this.setState({errorMsg: 'PaymentLinkForm.Declined.LockedSubtitle'});
      } else {
        this.setState({errorMsg: 'PaymentLinkForm.Declined.Subtitle2'});
      }
      this.setState({showDeclinedModal: true});
    }
  }

  async process(values) {
    const { dispatch, location, paymentLinks: {paymentLinkForPayment: {paymentLink}} } = this.props;
    const queryString = query.parse(location.search);
    const token = queryString.token;
    const recaptchaToken = await ReactRecaptcha3.getToken({ action: 'payment_link_payment' });
    this.setState({receiptEmail: values.email_address});
    try {
      const result = await PaymentUtil.processPaymentLinkPayment(values, token, recaptchaToken, this.props, paymentLink);
      this.handlePaymentResponse(result);
    } catch (error) {
      if (error === 'Server.Error.UnsuccessfulReCaptchaV3') {
        dispatch(reset('paymentLinkForm'));
      }
    }
  }

   processApplePay(user, applePaymentData, paymentData) {
    const { dispatch, location ,paymentLinks: {paymentLinkForPayment: {paymentLink}} } = this.props;

    const values = {
      ...paymentData,
      applePaymentData
    };

    this.setState({receiptEmail: values.applePaymentData?.shippingContact?.emailAddress});

    const queryString = query.parse(location.search);
    const token = queryString.token;

    let that = this;
    let errorMessage;

    return new Promise(function (resolve, reject) {
      PaymentUtil.processPaymentLinkWithApplePay(values, token, that.props, paymentLink).then(function (paymentResponse) {

        if (paymentResponse.payment && paymentResponse.payment.error) {
          errorMessage = paymentResponse.payment.error;
          that.setState({
            paymentCompleted: false,
            showDeclinedModal: true,
            paymentError: errorMessage
          });
          reject('Error: ' + errorMessage);
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code !== 'APR') {
          errorMessage = paymentResponse.payment.response.status_message;
          that.setState({
            paymentCompleted: false,
            showDeclinedModal: true,
            paymentError: 'Apple Pay cannot be used for this transaction. Please try another card.'
          });
          reject('Error: ' + errorMessage);
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code === 'APR') {

          if (paymentResponse.payment.response.is_partial_auth) {
            errorMessage = that.props.t(messages.errors.partialPayment.message);
            that.setState({
              paymentCompleted: false,
              showDeclinedModal: true,
              paymentError: errorMessage
            });
            reject('Error: ' + errorMessage);
          } else {
            that.setState({paymentCompleted: true});
            resolve();
          }

        } else {
          errorMessage = 'Sorry, something went wrong, please try again';
          that.setState({
            paymentCompleted: false,
            showDeclinedModal: true,
            paymentError: errorMessage
          });
          reject('Error: ' + errorMessage);
        }
        resolve();
      }).catch(error => {
        that.setState({paymentError: error});
        dispatch(reset('paymentLinkForm'));
        reject('Error: ' + error.message);
      });

    });

  }

  doAnother() {
    this.setState({
      paymentCompleted: false,
      paymentError: null,
      receiptEmail: ''
    });
  }

  redirect() {
    const { paymentLinks: { paymentLinkForPayment } } = this.props;
    const redirectUrl = paymentLinkForPayment?.paymentLink?.settings?.redirect_url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      this.doAnother();
    }
  }

  handleDeclinedModalClose() {
    this.setState({showDeclinedModal: false});
  }

  render() {

    const { t, paymentLinks: { isFetching, isProcessing, paymentLinkForPayment, paymentData } } = this.props;
    const { paymentCompleted, showDeclinedModal, receiptEmail, paymentError, errorMsg } = this.state;

    const merchant = paymentLinkForPayment?.merchant;
    const merchantSettings = paymentLinkForPayment?.merchantSettings;
    const linkData = paymentLinkForPayment?.paymentLink;
    const paymentLinksEnabled = merchantSettings?.payment_links_enabled;

    if ((!linkData && isFetching) || this.state.fetchingPosition) {
      return (
        <div className='paymentLinkContainer'>
          <Loading/>
        </div>
      )
    }

    let headerTitle = null;
    if (merchant) {
      if (merchantSettings?.logo) {
        headerTitle = (
          <MerchantLogo
            altText={merchant.business_name}
            logoBase64={merchantSettings.logo}
            size={100}
          />
        );
      } else {
        headerTitle = merchant.business_name;
      }
    } else {
      headerTitle = (
        <img className='merchantLogoImage' alt={'Payments Hub'} src={`${serverDomainUrl}images/paymenthubs/logo.svg`}/>
      );
    }

    let merchantUrl = null;
    if (merchantSettings?.website?.startsWith('http')) {
      merchantUrl = merchantSettings.website
    } else if (merchantSettings?.website) {
      merchantUrl = `https://${merchantSettings.website}`;
    }

    const header = !isFetching && (
      <div className='paymentLinkHeader'>
        {merchantUrl ? (
          <a href={merchantUrl} target='_blank' alt={merchant.business_name}>{headerTitle}</a>
        ) : headerTitle}
      </div>
    );

    if ((!linkData && !isFetching) || !linkData.active || !paymentLinksEnabled) {
      return (
        <div className='paymentLinkContainer'>
          {header}
          <div className='paymentLinkSection paymentLinkAlert'>
            <div className='title'>{t('PaymentLinkForm.NotFoundTitle')}</div>
            <div className='subtitle'>
              <div>{t('PaymentLinkForm.NotFoundLine1')}</div>
              <div>{t('PaymentLinkForm.NotFoundLine2')}</div>
            </div>

            <img src={`${serverDomainUrl}images/paymentLinks/paymentLinkNotFound.svg`}
                alt='Not found'
                className='image' />

            { linkData?.settings?.redirect_url && (
              <CountdownTimer secondsRemaining={paymentLinks.redirectSeconds}
                timeRemainingText={t('PaymentLinkForm.RedirectionText')}
                onCountdownComplete={this.redirect} />
            )}
          </div>
        </div>
      );
    }

    const modalBody = (
      <div className='declinedModalBody'>
        <p className='line1'>{t('PaymentLinkForm.Declined.Subtitle1')}</p>
        <p className='line2'>{t(errorMsg)}</p>
        {paymentError && <p className='paymentError'>{paymentError}</p>}
      </div>
    );

    const declinedPaymentModal = (
      <MessageDialog
        onRequestClose={this.handleDeclinedModalClose}
        onConfirm={this.handleDeclinedModalClose}
        confirmText={t('Okay')}
        isChoiceRequired={false}
        open={showDeclinedModal}
        titleText={t('PaymentLinkForm.Declined.Title')}
        bodyText={modalBody}
        externalClassName={'declinedPaymentModal'}
        hideCloseIcon={true}
      />
    );

    const authorizedAmount = roundToTwoDecimals(parseFloat(paymentData?.authorized_amt));
    const authorizedTipAmount = roundToTwoDecimals(parseFloat(paymentData?.authorized_tip_amt));
    const totalPaymentAmount =  roundToTwoDecimals(authorizedAmount + authorizedTipAmount);
    return (
      <div className='paymentLinkContainer'>
        {header}
        {!paymentCompleted ? (
          <>
            <PaymentLinkForm
              {...this.props}
              merchantSettings={merchantSettings}
              settings={linkData.settings}
              onSubmit={this.process}
              handleSubmitApplePay={this.processApplePay}
            />
            <div className='grecaptcha-protection'>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' target='_blank'>Privacy Policy</a> and
              <a href='https://policies.google.com/terms' target='_blank'>Terms of Service</a> apply.
            </div>
            {declinedPaymentModal}
          </>
        ) : (
          <div className='paymentLinkSection paymentLinkSuccess'>
            <div className='title'>{t('PaymentLinkForm.Success.Title')}</div>
            <div className='subtitle'>{t('PaymentLinkForm.Success.Subtitle', { email: receiptEmail })}</div>
            <PaymentLinkSuccessImg brandColor={this.props.brandColor}/>
            <div className='paymentInformation'>
              <div className='line highlight'>
                <div>{linkData?.settings?.title}</div>
                <div className='totalAmount'>{FormatTextUtil.formatCurrencyDoubleDecimals(totalPaymentAmount)}</div>
              </div>
              <div className='line'>
                <div>{`Paid ${moment().format('MMMM D YYYY, h:mm:ss a')}`}</div>
                <div>{`${paymentData?.network} ${paymentData?.last4}`}</div>
              </div>
            </div>

            { linkData?.settings?.redirect_url && (
              <CountdownTimer secondsRemaining={paymentLinks.redirectSeconds}
                timeRemainingText={t('PaymentLinkForm.RedirectionText')}
                onCountdownComplete={this.redirect} />
            )}

            <Button
              className='doAnotherButton enableHover'
              fullWidth
              onClick={this.doAnother}
              endIcon={<SvgIcon><RightChevronIcon/></SvgIcon>}
              label={t('PaymentLinkForm.Success.DoAnother')}
            />
          </div>
        )}
        {isProcessing ? (<UpdateSpinner/>) : null}
      </div>
    );
  }

}
