/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  arrayRemove,
  change,
  Field,
  getFormSyncErrors,
  getFormValues,
  reduxForm
} from 'redux-form';

import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import numeral from 'numeral';
import {
  Box,
  ListItemText,
  List,
  ListItemButton,
  ListItem,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Stack
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { setCustomer, getCustomer } from '../../actions/customerActions';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';

import CardPresentUtil from '../util/CardPresentUtil';
import FormUtil from '../util/FormUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import UserUtil from '../util/UserUtil';
import Validator from '../util/Validator';
import { roundToTwoDecimals } from '../util/CommonUtil';
import CustomerUtil from '../util/CustomerUtil';

import countryList, {countryStateList} from '../../constants/countryStateList';
import LineItemList from '../../components/LineItemList';
import messages from '../../constants/messages';
import routes from '../../constants/routes';
import SearchBarField from '../SearchBarField';
import VisibilityToggle from '../visibilityToggle';

import IconButton from '../shared/IconButton';
import Select from '../shared/Select';
import CustomToggle from '../shared/Toggle';
import TextField from '../shared/TextField';
import Tooltip from '../shared/Tooltip';
import Autocomplete from '../shared/Autocomplete'

import PaymentMethodSelect from './PaymentMethodSelect';
import TermsAndConditionToggle from '../shared/ach/TermsAndConditionToggle';
import CustomDatePicker from '../shared/DatePicker';
import {
  datePickerStyles as styles,
  vtResizableTextArea,
  vtStyles,
  cardContainerStyles,
  virtualTerminalFormContainerStyles
} from '../../jss/inlineStyles';

import { checkIsSpanish } from '../../locales/i18n';
import InvoiceUtil, {PaymentMethod} from '../util/InvoiceUtil';
import PaymentUtil from '../util/PaymentUtil';

import { setRewardCode, validateRewardCode } from '../../actions/loyaltyVpcActions';
import { toastr } from 'react-redux-toastr';
import BankingForm from '../shared/ach/BankingForm';
import applicationConstants from '../../constants/applicationConstants';
import {
  addExpressItemButtonStyles,
  addExpressItemsButtonStyles,
  addExpressItemsButtonTextStyles,
  addExpressItemsListStyles,
  addExpressItemsSearchRowStyles,
  addExpressItemsTextStyles,
  addExpressItemsSearchButtonStyles, addExpressItemsSearchDropdown
} from '../../jss/addExpressItemStyles';

import classnames from 'classnames';
import {loyaltySectionStyles} from '../../jss/virtualTerminalFormStyles';
import ResizableTextArea from '../shared/ResizableTextArea';
import CustomerSelector from '../CustomerSelector'

export const validate = (values) => {
  const partialPayment = values?.partialPayment ?? null;
  return Validator.validateOnlinePayments({ ...values, partialPayment}, true);
};

const normalizeCurrency = (value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 20);
const normalizeTaxRate = (taxRate) => FormatTextUtil.formatTaxRate(taxRate);
const normalizePercentage = (percentageValue) => FormatTextUtil.formatPercentageWithoutSymbol(percentageValue);
const defaultOrderDate = moment().toDate();

const statesList = countryStateList('United States');

export class VirtualTerminalFormComponent extends Component {

  constructor(props) {
    super(props);

    this.selectCustomer = this.selectCustomer.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.clearCardData = this.clearCardData.bind(this);
    this.setSelectExistingPaymentMethod = this.setSelectExistingPaymentMethod.bind(this);
    this.clearSelectExistingPaymentMethod = this.clearSelectExistingPaymentMethod.bind(this);
    this.findState = this.findState.bind(this);
    this.handleCardPresentAction = this.handleCardPresentAction.bind(this);
    this.cardPresentInputRequest = this.cardPresentInputRequest.bind(this);
    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.handleEnterCardManually = this.handleEnterCardManually.bind(this);
    this.handleToggleCardPresent = this.handleToggleCardPresent.bind(this);
    this.handleItemsSearchFocus = this.handleItemsSearchFocus.bind(this);
    this.handleItemsSearchBlur = this.handleItemsSearchBlur.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.renderCountryList = this.renderCountryList.bind(this);
    this.validateRewardCode = this.validateRewardCode.bind(this);
    this.deleteRewardDiscount = this.deleteRewardDiscount.bind(this);
    this.displayCustomerAddress = this.displayCustomerAddress.bind(this);
    this.togglePrePopulatedLoyaltyReward = this.togglePrePopulatedLoyaltyReward.bind(this);
    this.useBankingAccount = this.useBankingAccount.bind(this);
    this.toggleCashDiscount = this.toggleCashDiscount.bind(this);
    this.normalizeTaxIdField = this.normalizeTaxIdField.bind(this);

    this.state = {
      isCustomerDialogOpen: false,
      isSelectExistingPaymentMethod: false,
      activePaymentMethod: 0,
      cardVisibility: false,
      cardPresentUtil: new CardPresentUtil(props.dispatch),
      isItemsSearchDropdownOpen: false,
      emailAutoFocus: false,
      isInvalidCode: false,
      isAddressVisible: false,
      isPrePopulatedRewardActive: false,
      cashDiscountToggle: false
    };
  }

  componentDidMount() {
    const { initialValues } = this.props;
    this.setState({cashDiscountToggle: initialValues?.cashDiscounting});
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.deleteRewardDiscount();
    dispatch(setCustomer({}));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const enableSubmit = FormUtil.shouldEnableSubmit(nextProps);

    if (enableSubmit !== this.props?.userExperience?.enableFormSubmitButton) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }

    if (nextProps.selectedPaymentType !== this.props.selectedPaymentType) {
      this.setState({ isAddressVisible: false });
      this.handleEnterCardManually();
    }

    if (nextProps.customers?.selectedCustomer?.id !== this.props.customers?.selectedCustomer?.id) {
      this.setState({ isAddressVisible: !!nextProps.customers?.selectedCustomer?.address?.id, isPrePopulatedRewardActive: false });
    }

    FormUtil.virtualTerminalForm(this.props, nextProps);
  }

  componentDidUpdate(prevProps, prevState) {

    const currentValues = this.props?.currentValues;
    const prevCurrentValues = prevProps?.currentValues;

    const { cashDiscountToggle } = this.state;

    const checkFormInputPaymentMethod = currentValues?.paymentMethod !== prevCurrentValues?.paymentMethod;
    const checkSelectedPaymentMethod = currentValues?.selectedPaymentMethod !== prevCurrentValues?.selectedPaymentMethod;

    if (checkFormInputPaymentMethod || checkSelectedPaymentMethod) {
      PaymentUtil.disableCashDiscount(this.props, 'virtualTerminalForm', 'cashDiscounting', cashDiscountToggle);
    }
  }

  findState(isShipment) {

    const { shipmentCountry, destinationCountry } = this.props.currentValues;

    if (isShipment && shipmentCountry) {
      let selectedCountry = countryList.find((country) => country.anCode === shipmentCountry);
      return selectedCountry?.states?.map((state, index) => {
        return <MenuItem value={selectedCountry.stateCodes[index]} key={index}>{state}</MenuItem>;
      });
    } else if (!isShipment && destinationCountry) {
      let selectedCountry = countryList.find((country) => country.anCode === destinationCountry);
      return selectedCountry?.states?.map((state, index) => {
        return <MenuItem value={selectedCountry.stateCodes[index]} key={index}>{state}</MenuItem>;
      });
    }

  }

  handleRequestDelete(chip, index) {
    const { dispatch } = this.props;
    const fieldsToClean = [
      'customer_phone',
      'customer_first',
      'customer_last',
      'customer_street_address_1',
      'customer_street_address_2',
      'customer_state',
      'customer_zip',
      'customer_city'
    ];
    fieldsToClean.forEach((field) => {
      this.props.dispatch(change('virtualTerminalForm', field, ''));
    });
    dispatch(setCustomer({}));
    this.deleteRewardDiscount();
    dispatch(arrayRemove('virtualTerminalForm', 'customer_email_addresses', index));
  }

  async togglePrePopulatedLoyaltyReward() {
    const preExistingLoyaltyRewardInfo = this.props.customers?.selectedCustomer?.loyalty_vpc_status;

    if(!this.state.isPrePopulatedRewardActive && preExistingLoyaltyRewardInfo) {
      this.setState({isPrePopulatedRewardActive: true});

      await this.props.dispatch(setRewardCode(preExistingLoyaltyRewardInfo.reward_amount_type, preExistingLoyaltyRewardInfo.reward_amount));
      this.props.toggleLoyaltyRewardCode();
      this.props.dispatch(change('virtualTerminalForm', 'rewardCode', preExistingLoyaltyRewardInfo.reward_code));
      this.props.dispatch(change('virtualTerminalForm', 'rewardCodeInformation', this.props.loyaltyVpc.rewardCodeInfo));
    } else {
      this.setState({isPrePopulatedRewardActive: false});
      this.deleteRewardDiscount();
    }
  }

  clearCardData() {
    const {dispatch} = this.props;
    dispatch(change('virtualTerminalForm', 'selectedPaymentMethod', null));
    dispatch(change('virtualTerminalForm', 'cdigits', ''));
    dispatch(change('virtualTerminalForm', 'edate', ''));
    dispatch(change('virtualTerminalForm', 'cvv', ''));
    dispatch(change('virtualTerminalForm', 'zip', ''));
    dispatch(change('virtualTerminalForm', 'zipPlus4', ''));
    dispatch(change('virtualTerminalForm', 'saveCreditCard', false));
    dispatch(change('virtualTerminalForm', 'paymentMethod', null));
    dispatch(change('virtualTerminalForm', 'achTerms', false));
  }

  setSelectExistingPaymentMethod() {
    this.clearCardData();
    this.setState({isSelectExistingPaymentMethod: true});
  }

  clearSelectExistingPaymentMethod() {
    this.clearCardData();
    this.setState({isSelectExistingPaymentMethod: false});
  }

  selectCustomer(i) {
    const { dispatch, customers, user } = this.props;
    const cleanCustomers = CustomerUtil.cleanCustomers(customers)?.filteredData || [];
    const activeCustomer = cleanCustomers[i];

    const customerId = activeCustomer?.id;
    const isPaCustomer = activeCustomer?.is_pa_customer;

    if (!customers.selectedCustomer?.isFetching && customerId !== customers.selectedCustomer?.id) {
      dispatch(getCustomer(user, customerId, isPaCustomer));
    }
    dispatch(setSubmitButtonEnabled(true));

    this.deleteRewardDiscount();
    this.handleEnterCardManually();
  }

  setPaymentMethod(paymentMethod) {
    if (!!this.props.cardPresent?.isUsing) {
      this.props.onToggleCardPresent();
      this.props.dispatch(change('virtualTerminalForm', 'creditCardPaymentFlag', this.props.cardPresent.isUsing));
    }

    this.setSelectExistingPaymentMethod();

    this.props.dispatch(change('virtualTerminalForm', 'selectedPaymentMethod', paymentMethod));
  }

  handleCardPresentAction(cardPresentEvent, form, action) {

    const { cardPresent, handleSubmit, currentValues } = this.props;

    action(cardPresentEvent, currentValues, validate, handleSubmit, (cardPresent || {}).state || null);
  }

  handleDatePickerChange(date) {
    this.props.dispatch(change('virtualTerminalForm', 'orderDate', date ? moment(date) : null));
  }

  cardPresentInputRequest(cardPresentState) {
    return this.state.cardPresentUtil.cardPresentInputRequest(cardPresentState, this.props.submitting);
  }

  toggleCardNumberVisibility() {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  handleEnterCardManually() {
    if (!!this.props.cardPresent?.isUsing) {
      this.handleToggleCardPresent();
    }
    this.clearSelectExistingPaymentMethod();
    const {dispatch } = this.props;
    dispatch(change('virtualTerminalForm', 'paymentMethod', PaymentMethod.CREDIT_CARD));
  }

  useBankingAccount() {
    if (!!this.props.cardPresent?.isUsing) {
      this.handleToggleCardPresent();
    }
    this.clearSelectExistingPaymentMethod();
    const {dispatch } = this.props;
    dispatch(change('virtualTerminalForm', 'paymentMethod', PaymentMethod.BANKING_ACCOUNT));
  }

  handleToggleCardPresent() {
    this.props.onToggleCardPresent();
    this.props.dispatch(change('virtualTerminalForm', 'creditCardPaymentFlag', this.props.cardPresent.isUsing));
    this.clearSelectExistingPaymentMethod();
  }

  handleItemsSearchFocus() {
    this.setState({isItemsSearchDropdownOpen: true});
  }

  handleItemsSearchBlur() {
    setTimeout(() => {
      this.setState({isItemsSearchDropdownOpen: false});
    }, 500);
  }

  renderCountryList(country, i) {
    return (
      <MenuItem
        value={country.anCode ? country.anCode : country.name}
        key={i}
      >{country.name}</MenuItem>
    );
  }

  async validateRewardCode() {
    const { dispatch, user, currentValues: {rewardCode}, t, toggleLoyaltyRewardCode, customers } = this.props;

    if (rewardCode) {
      await dispatch(validateRewardCode(user, rewardCode));

      if (this.props.loyaltyVpc.rewardCodeInfo) {
        this.setState({isInvalidCode: false});
        toggleLoyaltyRewardCode();
        this.props.dispatch(change('virtualTerminalForm', 'rewardCodeInformation', this.props.loyaltyVpc.rewardCodeInfo));
      } else {
        this.props.dispatch(change('virtualTerminalForm', 'rewardCode', null));
        this.props.dispatch(change('virtualTerminalForm', 'rewardCodeInformation', null));
        this.setState({isInvalidCode: true});
        toastr.error(t('Error'), t('RewardCodeError'), {
          timeOut: 3000,
          showCloseButton: true,
          removeOnHover: false,
          progressBar: false
        });
      }
    }
  }

  deleteRewardDiscount() {
    this.props.dispatch(change('virtualTerminalForm', 'rewardCode', null));
    this.props.dispatch(change('virtualTerminalForm', 'rewardCodeInformation', null));
    this.props.toggleLoyaltyRewardCode(true);
  }

  displayCustomerAddress () {
    this.setState({ isAddressVisible: true });
  }

  toggleCashDiscount() {
    const { cashDiscountToggle } = this.state;
    this.setState({ cashDiscountToggle: !cashDiscountToggle });
  }

  normalizeTaxIdField = (value) => FormatTextUtil.formatWholeNumberWithDelimiter(value, 9)

  render() {
    const {
      addDiscountToCart,
      auth,
      cardPresent,
      currentValues,
      customers,
      handleSubmit,
      itemizedCart,
      itemCartHandlers,
      items,
      merchantSettings,
      openItemsDialog,
      selectedPaymentType,
      submitting,
      taxes,
      user,
      virtualTerminal,
      partialPayment,
      t,
      custom_field_1,
      loyaltyVpc,
      formErrors,
      vtFormValues,
      clearSelectedCustomer,
      clearCustomerFields
    } = this.props;

    const isLoyaltyEnabled = UserUtil.isLoyaltyEnabled(user, merchantSettings);
    const isPremiumPlusLoyalty = UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const isPremiumPlus = UserUtil.isPremiumPlusAccount(user);
    const isLoyaltyProgramEnabled = isLoyaltyEnabled && (isPremiumPlusLoyalty || isPremiumPlus);

    const activeLoyaltyReward = !!this.props.loyaltyVpc.rewardCodeInfo;
    const loyaltyIcon = IconUtil.getIcon('LoyaltyProgramIcon', '#2E2D2D', 23);

    const transactionsRoute = `${globalApplicationLabel.path}${routes.activity.root}${routes.activity.transactions}`;

    const debtRepaymentPayment = currentValues.paymentType === 'debtRepayment';
    const debitCardStyle = debtRepaymentPayment ? 'debitCard' : '';
    const hasItems = !!(items && items.salesItems && items.salesItems.length);
    const appRoutePrefix = globalApplicationLabel.path;
    const defaultTaxRate = !!merchantSettings?.geoTaxEnabled ? FormatTextUtil.formatNumberToThreeDecimals(taxes?.taxRate) : 0;

    CustomerUtil.cleanCustomers(customers);

    const isItemizedSale = selectedPaymentType === 'itemizedSale';
    const isIssueCredit = selectedPaymentType === 'issueCredit';

    const authorizedAmt = partialPayment?.authorized_amt;
    const remainingBalance = partialPayment?.remainingBalance;

    const formattedAuthorizedAmt = partialPayment && numeral(authorizedAmt).format('$0,0.00');
    const formattedRemainingBalance = partialPayment && numeral(remainingBalance).format('$0,0.00');
    const cardInfo = partialPayment && `(${partialPayment.network} - ${partialPayment.last4})`;
    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);

    const amountErrorMessageValues = {
      limit: numeral(applicationConstants.highTransactionLimitAmount).format('$0,0.00')
    };

    const formattedCustomersList = !!customers?.filteredData?.length
      ? customers.filteredData.map((customer, i) => {
        return {
          id: customer.id,
          fullName: `${customer.first_name} ${customer.last_name}`,
          email: customer.email_addresses[0] || [],
          phoneNumber: customer.phone_number,
          paymentMethod: !!customer.default_payment_method,
          reward: customer.loyalty_vpc_status.reward_is_eligible,
          index: i
        };
      })
      : [];

    let formTitle;

    switch (selectedPaymentType) {
      case 'debtRepayment':
        formTitle = `${t('DebtRepayment')}.`;
        break;
      case 'itemizedSale':
        formTitle = t('VirtualTerminalFormTitles.ItemizedSale');
        break;
      case 'issueCredit':
        formTitle = `${t('IssueAcredit')}.`;
        break;
      default:
        formTitle = t('VirtualTerminalFormTitles.OnePayment');
        break;
    }

    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const paymentMethodSelect = !isIssueCredit ? (
      <PaymentMethodSelect
        setPaymentMethod={this.setPaymentMethod}
        enterCardManually={this.handleEnterCardManually}
        toggleCardPresent={this.handleToggleCardPresent}
        isEnteringCardManually={currentValues.paymentMethod === PaymentMethod.CREDIT_CARD}
        isUsingCardReader={(!!this.props.cardPresent?.isUsing)}
        isIssueCredit={isIssueCredit}
        isBankingAccount={currentValues.paymentMethod === PaymentMethod.BANKING_ACCOUNT}
        useBankingAccount={this.useBankingAccount}
        selectedPaymentMethod={currentValues.selectedPaymentMethod}
        isPremiumPlusAccount={isPremiumPlusAccount}
        error={this.state.isSelectExistingPaymentMethod && formErrors?.selectedPaymentMethod}
        achEnabled={achEnabled}
        t={t}
      />
    ) : null;

    const categoryFlag = merchantSettings.express_category_enabled;

    const isBankAccountMethod = currentValues?.selectedPaymentMethod?.type === 'ach';
    const isBankAccountInput = currentValues?.paymentMethod === PaymentMethod.BANKING_ACCOUNT;
    const bankTypePaymentSelected = isBankAccountInput || isBankAccountMethod;
    const cashDiscountEnabled = merchantSettings.merchantSettings && merchantSettings.merchantSettings.cash_discount_enabled;
    const cashDiscountPerTransactionEnabled = merchantSettings.merchantSettings && merchantSettings.merchantSettings.cash_discount_per_transaction_enabled;
    const shouldDisabledCdToggle = submitting || !cashDiscountPerTransactionEnabled || bankTypePaymentSelected;

    const infoIcon = IconUtil.getIcon('InfoIcon', LabelUtil.getLabel().primaryColor);

    const cashDiscountingAmount = (
      <Box sx={vtStyles} className='cdAmountContainer'>
        <span>{`(${currentValues.cashDiscountingAmount})`}</span>
        <Tooltip
          component='span'
          placement='right'
          title={t(messages.virtualTerminal.cashDiscountInfo)}
        >
          {infoIcon}
        </Tooltip>
      </Box>
    );

    const paymentTypes = [
      <MenuItem value={'oneTimePayment'} key='oneTimePayment'>{t('OneTimePayment')}</MenuItem>
    ];

    if (auth.debtRepayment) {
      paymentTypes.push(<MenuItem value={'debtRepayment'} key='debtRepayment'>{t('DebtRepayment')}</MenuItem>);
    }
    if (hasItems) {
      paymentTypes.push(<MenuItem value={'itemizedSale'} key='itemizedSale'>{t('ItemizedSale')}</MenuItem>);
    }

    paymentTypes.push(<MenuItem value={'issueCredit'} key='issueCredit'>{t('IssueAcredit')}</MenuItem>);

    const processingDataSelection = [
      <MenuItem value={'none'} key='none'>
        {t('None')}
      </MenuItem>,
      (auth.processingLevel === 'L2' || auth.processingLevel === 'L3' ? (
        <MenuItem
          value={'levelTwo'}
          key='levelTwo'
          disabled={currentValues.paymentType === 'debtRepayment'}>
          {t('IncludeLevel2')}
        </MenuItem>) : null),
      (auth.processingLevel === 'L3' ? (
        <MenuItem
          value={'levelThree'}
          key='levelThree'
          disabled={currentValues.paymentType === 'debtRepayment'}>
          {t('IncludeLevel3')}
        </MenuItem>) : null)
    ];

    const cardReaderActionBtns = (
      cardPresent?.activityActions ?
        Object.keys(cardPresent.activityActions).map((key, index) => {
          return (
            <div key={index}>
              <a
                  onClick={(e) => this.handleCardPresentAction(e, this, cardPresent.activityActions[key])}
              >
                {t(key)}
              </a>
          </div>);
        }) : ''
    );


    const formHasCdigits = virtualTerminal.cardType && currentValues && currentValues.cdigits &&
                           currentValues.cdigits.length > 0;

    const isCustomerSelected = !!customers?.selectedCustomer?.id;

    const isSpanishLanguage = checkIsSpanish() ? 'spanishTranslation' : '';

    let paymentForm;

    if (this.props.cardPresent?.isUsing) {
      paymentForm = (
        <div className='cardReaderStatusWell'>
          <div className='cardReaderStatusIcon'><i></i></div>
          <div
            className={cardPresent.activityDetails ? 'cardReaderStatusInfo' : 'cardReaderStatusInfo noSublabel'}>
            <h2 className='cardReaderStatusTitle'>
              {t(cardPresent.activityTitle)}
            </h2>
            <h3 className='cardReaderStatusSubtitle'>
              {t(this.cardPresentInputRequest(cardPresent))}
            </h3>
          </div>
          <div className='cardReaderStatusAction'>
            {cardReaderActionBtns}
          </div>
        </div>
      )
    } else if (currentValues.paymentMethod === PaymentMethod.BANKING_ACCOUNT) {
      paymentForm = (
        <>
          <BankingForm submitting={submitting} t={t}/>
          <div className='saveCreditCardToggle'>
            {!isIssueCredit && isPremiumPlusAccount &&
              <>
                <Field
                  checked={!!this.value}
                  className='vtSwitch'
                  component={CustomToggle}
                  disabled={submitting}
                  label={t('SaveCreditCard.Label')}
                  name='saveCreditCard'
                />
                <p>
                  {t('SaveCreditCard.Notice.Responsibility')}
                  <br/>
                  {t('SaveCreditCard.Notice.Acknowledgement')}
                </p>
              </>
            }
          </div>
        </>
    )
      ;
    } else {
      paymentForm = (<div className='flex'>
        <Box className='cardContainerWithVisibilityToggle' sx={cardContainerStyles}>
          {/*The label text is in business.css to stop safari from saving the card number*/}
          <Field component={TextField}
                 label=' '
                 maxLength='25'
                 name='cdigits'
                 disabled={submitting}
                 className={`cardNumber textField ${debitCardStyle} ${isSpanishLanguage} ${this.state.cardVisibility ? 'visible' : ''}`}
                 normalize={value => FormatTextUtil.formatCardNumber(value)}
          />
          <span className='cardImage'>
                  {formHasCdigits ?
                    <img width='50' src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                    : <img width='50' src={`${serverDomainUrl}images/cards/unknown_card.png`}/>}
            </span>
          <VisibilityToggle
            visibility={this.state.cardVisibility}
            onClick={this.toggleCardNumberVisibility}/>
        </Box>

        <Field
          component={TextField}
          name='edate'
          label={t('Expiration')}
          hintText='MM/YY'
          normalize={FormatTextUtil.formatCreditCardExpiration}
          className='quarterToHalfScreen textField shrinkSpacing'
          disabled={submitting}
          maxLength='5'
        />

        <Field
          name='cvv'
          component={TextField}
          label={virtualTerminal.isAmex ? 'CID' : 'CVV'}
          hintText={virtualTerminal.isAmex ? 'CID' : 'CVV'}
          maxLength={'10'}
          disabled={submitting}
          normalize={FormatTextUtil.formatWholeNumber}
          className='quarterToHalfScreen textField'
        />
        <div className='flex'>
          <Field
            component={TextField}
            name='street_number'
            label={t('BillingStreet.Label')}
            hintText={t('BillingStreet.HintText')}
            maxLength='50'
            normalize={FormatTextUtil.formatStreetNumber}
            className='halfScreen textField'
          />

          <Field
            component={TextField}
            name='zip'
            label={t('ZipCode.Label')}
            hintText={t('ZipCode.HintText')}
            maxLength='5'
            disabled={submitting}
            normalize={FormatTextUtil.formatWholeNumber}
            className='quarterScreen textField'
          />

          <Field
            component={TextField}
            name='zipPlus4'
            label={t('ZipCodePlus.Label')}
            hintText={t('ZipCodePlus.HintText')}
            maxLength='4'
            disabled={submitting}
            normalize={FormatTextUtil.formatWholeNumber}
            className='quarterScreen textField'
          />
          <div className='vtAvsNotice'>
            {t('AvsNotice')}
          </div>
          <div className='saveCreditCardToggle'>
            {!isIssueCredit && isPremiumPlusAccount &&
              <>
                <Field
                  checked={!!this.value}
                  className='vtSwitch'
                  component={CustomToggle}
                  disabled={submitting}
                  label={t('SaveCreditCard.Label')}
                  name='saveCreditCard'
                />
                <p>
                  {t('SaveCreditCard.Notice.Responsibility')}
                  <br/>
                  {t('SaveCreditCard.Notice.Acknowledgement')}
                </p>
              </>
            }
          </div>
        </div>
      </div>);
    }

    let summarySectionSaleAmount, summarySectionSaleAmountWithLoyalty, summarySectionTaxFormatted, summarySectionTotal, cartAmounts, calculateCartAmount;

    if (isItemizedSale) {
      cartAmounts = InvoiceUtil.recalculateCart(itemizedCart, currentValues, this.props.loyaltyVpc.rewardCodeInfo);
      summarySectionSaleAmount = numeral(cartAmounts?.sub_total_amt).format('$0,0.00');
      summarySectionTaxFormatted = numeral(cartAmounts?.tax_amt).format('$0,0.00');
      summarySectionTotal = numeral(roundToTwoDecimals(cartAmounts?.total_amt)).format('$0,0.00');

    } else if (isIssueCredit) {
      summarySectionSaleAmount = numeral(currentValues.amount).format('$0,0.00');
      summarySectionTaxFormatted = numeral(0).format('$0,0.00');
      summarySectionTotal = numeral(currentValues.amount).format('$0,0.00');

    } else {
      calculateCartAmount = InvoiceUtil.recalculateCartCustomAmount(currentValues, this.props.loyaltyVpc.rewardCodeInfo);
      summarySectionSaleAmount = numeral(calculateCartAmount.subTotalWithoutLoyalty).format('$0,0.00');
      summarySectionSaleAmountWithLoyalty = numeral(calculateCartAmount.subTotal).format('$0,0.00');
      summarySectionTaxFormatted = numeral(calculateCartAmount.taxAmount).format('$0,0.00');
      summarySectionTotal = numeral(calculateCartAmount.total).format('$0,0.00');

    }

    let dropdownItemRows = (items.filteredItems?.length > 0) ?
      items.filteredItems.map((item, itemIndex) => {

        //For any particular item, the info displayed in the dropdown list should be that of the first price that isnt out of stock, if any, otherwise just show info of the first price
        let firstPriceInStockIndex = !item.is_trackable
          ? 0
          : item.details.prices.reduce((result, currentPrice, currentIndex) => {
              return (currentPrice.quantity > 0 && (result === null)) ? currentIndex : result;
            }, null);
        firstPriceInStockIndex = firstPriceInStockIndex || 0; //In case all prices are out of stock for an item

        const itemBarcode = (item.details.prices.length > 0)
          ? item.details.prices[firstPriceInStockIndex].barcode || t('NoBarcode')
          : t('NoBarcode');

        const itemPrice = (item.details.prices.length > 0)
          ? numeral(item.details.prices[firstPriceInStockIndex].price).format('$0,0.00')
          : '$0.00';

        const itemInStockQuantity = (item.is_trackable && item.details.prices.length > 0)
          ? item.details.prices[firstPriceInStockIndex].quantity > 0
            ? `${item.details.prices[firstPriceInStockIndex].quantity} ${t('InStock')}`
            : t('OutStock')
          : t('NotTracked');

        return (
          <ListItem key={itemIndex} sx={addExpressItemsSearchRowStyles} data-test-id='itemSearchRow'>
            <ListItemButton onClick={() => openItemsDialog(itemIndex)} sx={addExpressItemsSearchButtonStyles}>
              <Stack sx={addExpressItemsTextStyles}>
                <Stack direction='column' justifyContent='center' alignItems='flex-start'>
                  <div className='itemName'>{item.name}</div>
                  <div className='itemBarcode'>{itemBarcode}</div>
                </Stack>
                <Stack direction='column' justifyContent='center' alignItems='flex-end'>
                  <div className='itemPrice'>{itemPrice}</div>
                  <div className='itemInStockQuantity'>{itemInStockQuantity}</div>
                </Stack>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      }) : null;

    if (items.filteredDiscounts?.length > 0) {
      dropdownItemRows = dropdownItemRows || [];
      const itemsLength = dropdownItemRows.length;

      dropdownItemRows = dropdownItemRows.concat(items.filteredDiscounts.map((discount, discountIndex) => {

        const discountValue = discount.type === 'percent'
          ? `${discount.percentage}% ${t('Off')}`
          : `${numeral(discount.amount).format('$0,0.00')} ${t('Off')}`

        const formattedColor = discount.color ? '#' + discount.color : '#888C8D';
        const avatarStyles = { width: 30, height: 30};
        const disableDiscount = itemizedCart.sub_total_amt <= 0;
        const onClick = !disableDiscount ? () => addDiscountToCart(discountIndex) : undefined;

        return (
          <ListItem
            key={discountIndex + itemsLength}
            className={classnames('discountSearchRow', { disabled: disableDiscount })}
            onClick={onClick}
            sx={addExpressItemsSearchRowStyles}
          >
            <Stack sx={addExpressItemsTextStyles}>
              <Stack direction='row' alignItems='center' className='leftSide'>
                <IconButton disableTouchRipple className='discountIcon' size='large'>
                  <Avatar style={{ ...avatarStyles, backgroundColor: formattedColor }}>
                    {IconUtil.getIcon('DiscountIcon', '#FFFFFF')}
                  </Avatar>
                </IconButton>
                <div className='itemName'>{discount.name}</div>
              </Stack>
              <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                <div className='itemPrice'>{discountValue}</div>
              </Stack>
            </Stack>
          </ListItem>
        );

      }));
    }

    const taxRate = normalizeTaxRate(defaultTaxRate);

    const itemsSearchDropdown = (
      <Box className='itemsSearchDropdown threeQuarterScreen' sx={addExpressItemsSearchDropdown}>

        <div className='textField'>
          <div className='textFieldInfo'><label>{t('AddItems')}</label></div>
          <SearchBarField
            {...this.props}
            id='mainBar'
            optionalHandleFocus={this.handleItemsSearchFocus}
            optionalHandleBlur={this.handleItemsSearchBlur}
            autoCompleteOff={true}
            filterType={'secondaryFilter'}
          />
        </div>

        {defaultTaxRate > 0 &&
          <div className='taxNote'>
            <Trans i18nKey='TaxNote'>
              Default tax rate is set to {{taxRate}}. Configure tax in <Link to={appRoutePrefix + routes.account.root + routes.account.settings} className='linkLike'>Business Settings.</Link>
            </Trans>
          </div>
        }

        <List sx={addExpressItemsListStyles} className={this.state.isItemsSearchDropdownOpen ? '' : 'hideMe'}>
          <ListItem data-test-id='addExpressItemButton' disablePadding sx={addExpressItemButtonStyles} onClick={() => openItemsDialog(-1)}>
            <ListItemButton sx={addExpressItemsButtonStyles}>
              <ListItemText sx={addExpressItemsButtonTextStyles} primary={t('AddExpressItem')} />
            </ListItemButton>
          </ListItem>
          {dropdownItemRows}
        </List>
      </Box>
    );

    const preExistingLoyaltyRewardInfo = customers?.selectedCustomer?.loyalty_vpc_status;
    const isCustomerEnrolledInLoyalty = preExistingLoyaltyRewardInfo?.opted_in_loyalty;
    const isValidPreExistingLoyaltyReward = preExistingLoyaltyRewardInfo?.reward_is_eligible && preExistingLoyaltyRewardInfo?.reward_amount && preExistingLoyaltyRewardInfo?.reward_amount_type;

    const isCustomerFormFilled = (
        Boolean(vtFormValues?.customer_email_addresses?.length) ||
        Boolean(vtFormValues?.customer_phone?.length)) &&
        Boolean(vtFormValues?.customer_first?.length) &&
        Boolean(vtFormValues?.customer_last?.length);

    const isZeroDollarsBeforeLoyaltyReward = !activeLoyaltyReward
      && (
        numeral(currentValues.amount).value() <= 0
        || (isItemizedSale && numeral(itemizedCart.sub_total_amt).value() <= 0)
      );

    return (
      <Box className='virtualTerminalFormContainer' sx={virtualTerminalFormContainerStyles}>

        <form onSubmit={handleSubmit}>
          <fieldset disabled={cardPresent?.isWorking}>
            <div className='virtualTerminalForm'>
              <h2 className='formTitle'>{formTitle}</h2>
              {isIssueCredit && (
                <p className='formDescription'>
                  <Trans i18nKey={'IsIssueCreditDescription'}>
                      Issuing a credit is not related to an existing transaction. For a refund or void related to an existing transaction, visit the <Link className='formDescriptionLink' to={transactionsRoute}>Transactions</Link> page.
                  </Trans>
                </p>

              )}

              {/* Payment type is hidden, it's selected by the FilterPanel in VirtualTerminal */}
              <div className='paymentTypeContainer'>
                <div className='formHeading'>{t('VirtualTerminalFormHeading')}</div>
                <div className='formItems'>
                  <div className='flex'>

                    <Field
                      className='alignBottom fullScreen paymentType'
                      component={Select}
                      disabled={this.props.disableFields}
                      name='paymentType'>
                      {paymentTypes}
                    </Field>
                  </div>
                </div>
              </div>

              <div className='optionalReceipt'>
                <div className='formItems formItemsRelative'>

                  <Field
                    component={CustomerSelector}
                    name='customer_email_addresses'
                    id='customer_email_addresses'
                    label={t('CustomerEmailAddress')}
                    placeholder={t('EnterEmail')}
                    disabled={submitting}
                    options={formattedCustomersList}
                    selectCustomer={this.selectCustomer}
                    clearSelectedCustomer={clearSelectedCustomer}
                    clearCustomerFields={clearCustomerFields}
                    selectedPaymentType={selectedPaymentType}
                    selectedCustomer={customers?.selectedCustomer}
                  />

                  <Field
                    component={TextField}
                    name='customer_phone'
                    label={t('CustomerPhoneNumber')}
                    hintText={t('EnterPhoneNumber')}
                    disabled={submitting}
                    normalize={FormatTextUtil.formatPhoneNumber}
                    maxLength='50'
                  />
                  <Field
                    component={TextField}
                    name='customer_first'
                    label={t('CustomerName')}
                    hintText={t('EnterFirstName')}
                    maxLength='50'
                    disabled={isCustomerSelected || submitting}
                    className='halfScreen textField customer_first'
                  />
                  <Field
                    component={TextField}
                    name='customer_last'
                    label={t('CustomerLastName')}
                    hintText={t('EnterLastName')}
                    maxLength='50'
                    disabled={isCustomerSelected || submitting}
                    className='halfScreen textField customer_last'
                  />

                  {this.state.isAddressVisible
                    ? <div className='addressInfo'>
                      <div className='customerStreet'>
                        <div className='customerStreet1Field'>
                          <Field
                            component={TextField}
                            name='customer_street_address_1'
                            label={t('CustomerStreetAddress1')}
                            hintText={t('EnterStreetAddress1')}
                            disabled={ submitting}
                          />
                        </div>

                        <div className='customerStreet2Field'>
                          <Field
                            component={TextField}
                            name='customer_street_address_2'
                            label={t('CustomerStreetAddress2')}
                            hintText={t('EnterStreetAddress2')}
                            disabled={submitting}
                          />
                        </div>
                      </div>

                      <div className='customerState'>
                        <Field
                            className='thirdToHalfScreen textField shipmentState'
                            component={Autocomplete}
                            props={{
                              defaultValue: statesList.find(state => customers?.selectedCustomer?.address?.state === state.key)
                            }}
                            label={t('BusinessForm.State')}
                            name='customer_state'
                            options={statesList}
                            t={t}
                        />
                      </div>

                      <div className='customerCityZip'>
                        <div className='customerCity'>
                          <Field
                            component={TextField}
                            name='customer_city'
                            label={t('City')}
                            hintText={t('EnterCity')}
                            disabled={ submitting}
                          />
                        </div>

                        <div className='customerZip'>
                          <Field
                            component={TextField}
                            name='customer_zip'
                            label={t('Zip')}
                            hintText={t('EnterZipCode')}
                            disabled={ submitting}
                          />
                        </div>
                      </div>
                    </div>
                    : <a onClick={this.displayCustomerAddress}>{t('AddCustomerAddress')}</a>
                  }

                </div>
              </div>

              <div className='transactionAmount'>
                <div className='formItems'>

                  <div className='flex'>

                    {(categoryFlag && !isItemizedSale && !isIssueCredit) ? <Field
                      component={Select}
                      label={t('Category.Label')}
                      style={{ height: 46 }}
                      name='selectedCategory'
                      className='categoryNameWrapper'>
                      <MenuItem value={-1} key={-1} className='categoryItem'>
                        <span className='bubble'>
                          {IconUtil.getIcon('DotIcon', '#8A8C8C')}
                        </span>
                        {t('Category.NoCategory')}
                      </MenuItem>
                      {
                        this.props.initialValues.categories.map((item, i) => (
                          <MenuItem value={item.id} key={i} className='categoryItem'>
                            <span className='bubble'>
                              {IconUtil.getIcon('DotIcon', `#${item.color || '8A8C8C'}`)}
                            </span>
                            {item.name}
                          </MenuItem>
                        ))
                      }
                    </Field> : null}

                  </div>
                  <div className='flex'>

                    {!isItemizedSale && !isIssueCredit &&
                      <Field
                        component={ResizableTextArea}
                        name='description'
                        label={t('Description.Label')}
                        hintText={t('Description.HintText')}
                        disabled={submitting}
                        className={'textField descriptionField'}
                        sx={vtResizableTextArea}
                        maxLength={100}
                      />
                    }

                    {isItemizedSale && itemsSearchDropdown}

                    <Field
                      component={TextField}
                      label={t('InvoiceNumber.Label')}
                      name='invoice_number'
                      hintText={t('InvoiceNumber.HintText')}
                      maxLength='25'
                      className={`textField invoiceNumber ${(isItemizedSale) ? 'quarterScreen' : 'halfScreen'} ${isIssueCredit ? 'hideMe' : ''}`}
                    />

                  </div>
                  <div className='flex'>

                    {!isItemizedSale && <Field
                      component={TextField}
                      name='amount'
                      label={`${isIssueCredit ? t('CreditAmountLabel') : t('PaymentAmount')}`}
                      hintText='$0.00'
                      className='amount textField fullScreen'
                      maxLength='14'
                      disabled={submitting}
                      errorMessageValue={amountErrorMessageValues}
                      normalize={normalizeCurrency}
                    />}

                    {!debtRepaymentPayment && !isItemizedSale && !isIssueCredit && <Field
                      component={TextField}
                      name='taxRate'
                      label={t('TaxRate')}
                      maxLength='7'
                      hintText='00.000'
                      className={'textField customTaxRate halfScreen'}
                      disabled={submitting}
                      normalize={normalizePercentage}
                      InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                    />}

                    {isIssueCredit && <Field
                      component={TextField}
                      label={t('Note')}
                      name='note'
                      hintText={t('Optional')}
                      maxLength='100'
                      className='textField descriptionField fullScreen'
                    />}

                  </div>

                  {(custom_field_1 && !isIssueCredit) && <Field
                      component={TextField}
                      name='custom_field_1'
                      validate={Validator.validateCustomField}
                      label={custom_field_1.label}
                      className='textField fullScreen'
                      disabled={submitting}
                    />}

                  {isLoyaltyProgramEnabled && isCustomerEnrolledInLoyalty && !isIssueCredit &&
                    <Box sx={loyaltySectionStyles}>
                      <label className='loyaltySectionTitle'>{t('RewardCode')}</label>

                      {
                        isValidPreExistingLoyaltyReward &&
                          <div className='flex'>
                            <div className='existingLoyaltyReward'>
                              <p>{`${t('LoyaltyReward')} ${preExistingLoyaltyRewardInfo.reward_amount_type === 'percent' ? `(${preExistingLoyaltyRewardInfo.reward_amount}% ${t('LoyaltyRewardOff')})` : `($${preExistingLoyaltyRewardInfo.reward_amount} ${t('LoyaltyRewardOff')})`}`}</p>
                              <CustomToggle
                                input={{
                                  value: this.state.isPrePopulatedRewardActive,
                                  onChange: this.togglePrePopulatedLoyaltyReward
                                }}
                                disabled={isZeroDollarsBeforeLoyaltyReward}
                              />
                            </div>
                          </div>
                      }

                      <div className='flex'>
                        <Field
                          component={TextField}
                          name='rewardCode'
                          maxLength=''
                          hintText={t('RewardCodeHintText')}
                          className={`textField fullScreen ${loyaltyVpc.isFetching ? 'rewardFieldFetching' : 'rewardField'}`}
                          disabled={submitting
                            || !!this.props.loyaltyVpc.rewardCodeInfo
                            || !isCustomerFormFilled
                            || !!this.state.prePopulatedLoyaltyRewardInformation
                            || isZeroDollarsBeforeLoyaltyReward
                          }
                          InputProps={{ endAdornment:
                            <>
                              {
                                isCustomerFormFilled && !this.state.isPrePopulatedRewardActive && !isZeroDollarsBeforeLoyaltyReward &&
                                  <>
                                    {
                                      loyaltyVpc.isFetching ?
                                      <CircularProgress/>
                                      :
                                      activeLoyaltyReward ?
                                          <InputAdornment position='end' onClick={this.deleteRewardDiscount}>{t('Remove')}</InputAdornment>
                                        :
                                          <InputAdornment position='end' onClick={this.validateRewardCode}>{t('Apply')}</InputAdornment>
                                    }
                                  </>
                              }
                            </>
                          }}
                        />
                      </div>
                      {
                        this.state.isInvalidCode && !currentValues.rewardCode &&
                          <p className='invalidRewardCodeIndicator'>{t('EnterValidRewardCode')}</p>
                      }
                    </Box>
                  }

                  <div className='flex cashDiscountingContainer'>

                    {!debtRepaymentPayment && !isIssueCredit && cashDiscountEnabled &&
                      <>
                        <Field
                          component={CustomToggle}
                          name='cashDiscounting'
                          label={t('CashDiscounting')}
                          className='switchCustomToggle'
                          onChange={this.toggleCashDiscount}
                          checked={!!this.value}
                          disabled={shouldDisabledCdToggle}
                        />
                        {cashDiscountingAmount}
                      </>
                     }

                  </div>
                </div>
              </div>
            </div>

            <div className='paymentInformation'>
              <div className='formItems' id='paymentForm'>
                {paymentMethodSelect}
                {!this.state.isSelectExistingPaymentMethod && paymentForm}
              </div>
            </div>

            {isBankAccountMethod && <TermsAndConditionToggle t={t} submitting={submitting} margin={true} />}

            {(auth.processingLevel === 'L2' || auth.processingLevel === 'L3') && !isIssueCredit && (
              <div className='flex'>
                <Field
                  style={{ height: 46 }}
                  component={Select}
                  label={t('AdditionalData')}
                  disabled={this.props.disableFields}
                  className='alignBottom fullScreen processingDataSelection'
                  name='processingDataSelection'>
                  {processingDataSelection}
                </Field>
              </div>
            )}

            {(currentValues.processingDataSelection === 'levelTwo' || currentValues.processingDataSelection === 'levelThree') && currentValues.paymentType !== 'debtRepayment' && !isIssueCredit &&
              (
                <div className='customerAndTax'>
                  <div className='flex'>
                    <Field
                      component={TextField}
                      maxLength='25'
                      disabled={this.props.disableFields}
                      className='fullScreen textField customerCode'
                      name='customerCode'
                      label={t('CustomerCode')}
                      normalize={FormatTextUtil.formatAlphaNumeric} />
                  </div>
                  <div className='flex'>
                    <Field
                      component={TextField}
                      disabled={true}
                      maxLength='14'
                      label={t('TaxAmount')}
                      className='alignBottom halfScreen textField taxAmount'
                      name='taxAmount'
                    />
                    <Field
                      component={TextField}
                      maxLength='50'
                      className='alignBottom halfScreen textField taxId'
                      name='taxId'
                      disabled={submitting}
                      label={t('TaxId')}
                      format={FormatTextUtil.formatTaxId}
                      normalize={this.normalizeTaxIdField}
                    />
                  </div>
                </div>
              )
            }

            <br/>
            <br/>
            <br/>

            {currentValues.processingDataSelection === 'levelThree' && currentValues.paymentType !== 'debtRepayment' && !isIssueCredit &&
              (
                <div className='levelThree'>
                  <div className='flex'>
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='26'
                      className='fullScreen textField itemDescription'
                      name='itemDescription'
                      label={t('ItemDescription')} />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='13'
                      className='thirdScreen textField itemQuantity'
                      name='itemQuantity'
                      label={t('ItemQuantity')}
                      normalize={FormatTextUtil.formatDecimals} />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='12'
                      className='thirdScreen textField measureUnit'
                      name='measureUnit'
                      label={t('UnitMeasure')}
                      normalize={FormatTextUtil.formatAlphaNumeric} />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='13'
                      className='thirdScreen textField unitPrice'
                      name='unitPrice'
                      label={t('UnitPrice')}
                      normalize={normalizeCurrency} />
                    <Field
                      component={TextField}
                      maxLength='15'
                      disabled={this.props.disableFields}
                      className='halfScreen textField productCode'
                      name='productCode'
                      label={t('ProductCode')}
                      normalize={FormatTextUtil.formatAlphaNumeric} />
                    <Field
                      className='halfScreen orderDate'
                      component={CustomDatePicker}
                      dateFormat='YYYY-MM-DD'
                      disabled={this.props.disableFields}
                      label={t('OrderDate')}
                      input={{
                        value: moment(currentValues.orderDate),
                        onChange: (date) => this.handleDatePickerChange(date),
                      }}
                      minDate={moment().subtract(6, 'months')}
                      name='orderDate'
                      styles={styles.orderDate}
                      t={t}
                    />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='12'
                      className='halfScreen textField commodityCode'
                      name='commodityCode'
                      normalize={FormatTextUtil.formatAlphaNumeric}
                      label={t('CommodityCode')} />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='50'
                      className='halfScreen textField requestorName'
                      name='requestorName'
                      label={t('RequestorName')} />
                    <Field
                      component={TextField}
                      maxLength='50'
                      className='halfScreen textField companyName'
                      name='companyName'
                      disabled={submitting}
                      label={t('CompanyName')} />
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='50'
                      className='halfScreen textField shipmentId'
                      name='shipmentId'
                      label={t('ShipmentId')}
                      normalize={FormatTextUtil.formatAlphaNumeric} />
                  </div>
                  <div className='flex'>
                    <Field
                      component={Select}
                      label={t('ShipmentCountry')}
                      className='thirdToHalfScreen textField shipmentCountry'
                      name='shipmentCountry'
                      disabled={submitting}
                      ref='shipmentCountry'>
                      <MenuItem value={-1} disabled={true}>{t('SelectCountry')}</MenuItem>
                      {
                        countryList.map(this.renderCountryList)
                      }
                    </Field>
                    <Field
                      component={Select}
                      label={t('ShipmentState')}
                      className='thirdToHalfScreen textField shipmentState'
                      name='shipmentState'
                      disabled={submitting}
                      ref='shipmentState'>
                      {this.findState(true)}
                    </Field>
                    <Field
                      component={TextField}
                      className='thirdToHalfScreen textField shipmentZipCode'
                      name='shipmentZipCode'
                      maxLength='10'
                      disabled={submitting}
                      label={t('ShipmentZipCode')}
                      normalize={FormatTextUtil.formatZip} />
                  </div>
                  <div className='flex'>
                    <Field
                      component={Select}
                      label={t('DestinationCountry')}
                      className='thirdToHalfScreen textField destinationCountry'
                      name='destinationCountry'
                      disabled={submitting}
                      ref='destinationCountry'>
                      <MenuItem value={-1} disabled={true}>{t('SelectCountry')}</MenuItem>
                      {
                        countryList.map(this.renderCountryList)
                      }
                    </Field>
                    <Field
                      component={Select}
                      label={t('DestinationState')}
                      className='thirdToHalfScreen textField destinationState'
                      name='destinationState'
                      disabled={submitting}
                      ref='destinationState'>
                      {this.findState(false)}
                    </Field>
                    <Field
                      component={TextField}
                      disabled={this.props.disableFields}
                      maxLength='10'
                      className='thirdToHalfScreen textField destinationZipCode'
                      name='destinationZipCode'
                      label={t('DestinationZipCode')}
                      normalize={FormatTextUtil.formatZip} />
                  </div>
                  <div className='flex'>
                    <Field
                      component={TextField}
                      maxLength='13'
                      className='thirdScreen textField discountAmount'
                      name='discountAmount'
                      disabled={submitting}
                      hintText='$0.00'
                      label={t('DiscountAmount')}
                      normalize={normalizeCurrency} />
                    <Field
                      component={TextField}
                      disabled={submitting}
                      maxLength='13'
                      className='thirdScreen textField dutyAmount'
                      name='dutyAmount'
                      hintText='$0.00'
                      label={t('DutyAmount')}
                      normalize={normalizeCurrency} />
                    <Field
                      component={TextField}
                      maxLength='13'
                      className='thirdScreen textField freightAmount'
                      name='freightAmount'
                      disabled={submitting}
                      hintText='$0.00'
                      label={t('FreightAmount')}
                      normalize={normalizeCurrency} />
                  </div>
                </div>
              )
            }

          </fieldset>
        </form>

        <div className='summarySection'>
          <h3 className='summaryTitle'>
            {`
              ${isIssueCredit
                ? t('Summary')
                : `${t('Purchases')} ${isItemizedSale ? t('PurchasesItemizedSale') : ''}`}
            `}
          </h3>

          { !isItemizedSale &&
            <div className='amounts'>
              <span className='amountName'>{`${isIssueCredit ? t('CreditAmountLabel') : t('CustomAmount')}`}</span>
              <span className='amountValue'>{summarySectionSaleAmount}</span>
            </div>
          }

          { isItemizedSale && itemizedCart?.item_ids.length === 0 && itemizedCart.receipt_discount_id.length === 0 &&
            <div className='amounts'>
              <span className='amountName'>{t('NoItems')}</span>
              <span className='amountValue'>{'$0.00'}</span>
            </div>
          }

          { isItemizedSale &&
            <LineItemList
              {...this.props}
              isInvoice={false}
              selectedReceipt={cartAmounts}
              itemCartHandlers={itemCartHandlers}
              showCartDiscounts={true}
              hideInputFields={true}
            />
          }

          {
            activeLoyaltyReward &&
              <div className='loyaltyDiscount'>
                {loyaltyIcon}
                <span>{t('LoyaltyReward')}</span>
                <span>
                  {
                    this.props.loyaltyVpc.rewardCodeInfo?.type === 'dollar' ?
                      `${cartAmounts?.loyalty_discount_amt ? numeral(cartAmounts?.loyalty_discount_amt).format('$0,0.00') : numeral(calculateCartAmount?.rewardDiscount).format('$0,0.00')} ${t('LoyaltyRewardOff')}`
                    :
                      `${this.props.loyaltyVpc.rewardCodeInfo.amount}% ${t('LoyaltyRewardOff')}`
                  }
                </span>
              </div>
          }
          { !isIssueCredit &&
            <div className='subtotals'>
              <span className='subtotalName'>{t('Subtotal')}</span>
              <span className='subtotalValue'>{activeLoyaltyReward && summarySectionSaleAmountWithLoyalty ? summarySectionSaleAmountWithLoyalty : summarySectionSaleAmount}</span>
            </div>
          }

          { !isIssueCredit &&
            <div className='subtotals'>
              <span className='subtotalName'>{t('Tax')}</span>
              <span className='subtotalValue'>{summarySectionTaxFormatted}</span>
            </div>
          }

          <div className='subtotals total'>
            <span className='subtotalName'>{t('Total')}</span>
            <span className='subtotalValue'>{summarySectionTotal}</span>
          </div>

          { partialPayment && (<div className='partialPaymentSection'>
            <br/>
            <div className='subtotals'>
              <span className='subtotalName'>{t('PartialAuth', { cardInfo })}</span>
              <span className='subtotalValue'>{formattedAuthorizedAmt}</span>
            </div>
            <div className='subtotals'>
              <span className='subtotalName'>{t('BalanceRemaining')}</span>
              <span className='subtotalValue'>{formattedRemainingBalance}</span>
            </div>
          </div>)
          }

        </div>

      </Box>
    );
  }
}

let VirtualTerminalForm = reduxForm({
  form: 'virtualTerminalForm',
  fields: [
    'amount',
    'cdigits',
    'commodityCode',
    'companyName',
    'customerCode',
    'customer_email_addresses',
    'customer_first',
    'customer_last',
    'customer_phone',
    'cvv',
    'edate',
    'itemDescription',
    'itemQuantity',
    'measureUnit',
    'paymentType',
    'paymentMethod',
    'processingDataSelection',
    'productCode',
    'requestorName',
    'saveCreditCard',
    'street_number',
    'taxAmount',
    'taxId',
    'taxRate',
    'taxable',
    'unitPrice',
    'zip',
    'zipPlus4',
    'custom_field_1',
    'achTerms'
  ],
  validate,
  enableReinitialize: false
})(VirtualTerminalFormComponent);

function mapStateToProps(state, ownProps) {

  const customFields = state?.merchantSettings?.merchantSettings?.custom_fields;
  let custom_field_1 = null;
  if (customFields?.vt_fields?.length > 0) {
    custom_field_1 = customFields.vt_fields[0].custom_field_1;
  }
  const currentValues = getFormValues('virtualTerminalForm')(state) || {};
  const formErrors = getFormSyncErrors('virtualTerminalForm')(state) || {}
  const geoLocation = ownProps.taxes.geoLocation || {};
  const taxRate = FormatTextUtil.formatNumberToThreeDecimals(ownProps.taxes?.taxRate);
  const customer_email_addresses = currentValues.customer_email_addresses || [];
  const existingCategories = ownProps.items.categories && ownProps.items.categories.length > 0 ? ownProps.items.categories : [];
  const cashDiscounting = ownProps?.merchantSettings?.merchantSettings?.cash_discount_enabled;

  const selectedCategory = currentValues.selectedCategory || -1;

  return {
    initialValues: {
      amount: currentValues.amount,
      subtotalPreDiscounts: currentValues.subtotalPreDiscounts,
      description: currentValues.description,
      note: currentValues.note,
      cdigits: currentValues.cdigits,
      invoice_number: currentValues.invoice_number,
      zip: currentValues.zip,
      zipPlus4: currentValues.zipPlus4,
      edate: currentValues.edate,
      cvv: currentValues.cvv,
      street_number: currentValues.street_number,
      location: geoLocation,
      taxRate: normalizePercentage(taxRate),
      customer_email_addresses: customer_email_addresses,
      customer_phone: FormatTextUtil.formatPhoneNumber(ownProps?.customers?.selectedCustomer?.phone_number) || '',
      cashDiscounting: cashDiscounting || currentValues.cashDiscounting,
      cashDiscountingAmount: currentValues.cashDiscountingAmount,
      customer_first: ownProps?.customers?.selectedCustomer?.first_name || '',
      customer_last: ownProps?.customers?.selectedCustomer?.last_name || '',
      customer_zip: currentValues.customer_zip,
      customer_state: currentValues.customer_state,
      customer_city: currentValues.customer_city,
      customer_street_address_1: currentValues.customer_street_address_1,
      customer_street_address_2: currentValues.customer_street_address_2,
      categories: existingCategories,
      selectedCategory: selectedCategory,
      paymentType: ownProps.selectedPaymentType,
      processingDataSelection: 'none',
      customerCode: currentValues.customerCode,
      taxAmount: currentValues.taxAmount,
      taxId: currentValues.taxId,
      rewardCode: currentValues.rewardCode,
      rewardCodeInformation: currentValues.rewardCodeInformation,
      itemDescription: currentValues.itemDescription,
      itemQuantity: currentValues.itemQuantity,
      measureUnit: currentValues.measureUnit,
      unitPrice: currentValues.unitPrice,
      productCode: currentValues.productCode,
      orderDate: defaultOrderDate,
      commodityCode: currentValues.commodityCode,
      requestorName: currentValues.requestorName,
      companyName: currentValues.companyName,
      shipmentId: currentValues.shipmentId,
      shipmentCountry: currentValues.shipmentCountry,
      shipmentState: currentValues.shipmentState,
      shipmentZipCode: currentValues.shipmentZipCode,
      destinationCountry: currentValues.destinationCountry,
      destinationState: currentValues.destinationState,
      destinationZipCode: currentValues.destinationZipCode,
      discountAmount: currentValues.discountAmount,
      dutyAmount: currentValues.dutyAmount,
      freightAmount: currentValues.freightAmount,
      saveCreditCard: currentValues.saveCreditCard,
      selectedPaymentMethod: currentValues.selectedPaymentMethod,
      paymentMethod: PaymentMethod.CREDIT_CARD,
      creditCardPaymentFlag: !ownProps.cardPresent?.isUsing,
      custom_field_1: currentValues.custom_field_1,
      accountType: 'checking'
    },
    custom_field_1,
    currentValues,
    formErrors
  };
}

VirtualTerminalForm = connect(mapStateToProps)(VirtualTerminalForm);

export default VirtualTerminalForm;
